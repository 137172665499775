// import logo from './logo.svg';
import './App.scss';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import MainLayout from './MainLayout';
import 'antd/dist/antd.min.css';

function App() {
    useEffect(() => {
    }, []);

    return (
        <Router>
            <MainLayout />
        </Router>
    );
}

export default App;
