import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Fetch } from '../../utils/fetchData';
import './apply.scss';
import {
  Form,
  Input,
  Radio,
  Space,
  Button,
  Picker,
  DatePicker,
  SearchBar,
  Toast
} from 'antd-mobile';
import BANNER from '../../assets/banner.png';
import LOGO from '../../assets/logo_white.png';
import {
  ethnicityColumns,
  limit20,
  limit50,
  limit100,
} from '../../utils/check';
import dayjs from 'dayjs';
import ContactComp from './ContactComp';
import ConfirmModal from '../../Components/ConfirmModal'; // 确保路径正确

const ApplyView = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [reqSuccess, setReqSuccess] = useState(false);
  const [pickerVisible, setPickerVisible] = useState(false);
  const [datePickerVisible, setDatePickerVisible] = useState(false);
  const [graduationDatePickerVisible, setGraduationDatePickerVisible] = useState(false);

  const [ethnicityArr, setEthnicityArr] = useState(ethnicityColumns);

  const title1 = '华侨大学日本国际教育项目';
  const title2 = '入学申请';
  const p1 = '华侨大学日本国际教育项目的自主招生考试时间持续更新,请考生关注微信公众号及学院招生办老师发布通知信息。';
  const p2 = '咨询电话:0595-22692190';

  const formatTimeValue = (time) => {
    return dayjs(time).unix();
  };

  const handleConfirm = () => {
    setIsModalVisible(false);
    // 执行其他操作，例如提交表单或发送请求
    console.log('User confirmed');
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmit = () => {
    form.validateFields()
      .then((res) => {
        if (res?.date_of_birth) res.date_of_birth = formatTimeValue(res.date_of_birth);
        if (res?.graduation_date) res.graduation_date = formatTimeValue(res.graduation_date);
        if (res?.ethnicity?.length) res.ethnicity = res.ethnicity[0];
        setIsLoading(true);
        Fetch.POST('/api/applications', {}, res).then(res => {
          setIsLoading(false);
          if (res.code === 200) {
            setReqSuccess(true);
          } else {
            Toast.show({ content: res.message, duration: 3000 });
          }
        });
      })
      .catch((err) => {
        console.log('====== err', err);
      });
  };

  const handlePcikViewSearch = (searchText) => {
    if (!searchText?.length) {
      setEthnicityArr(ethnicityColumns);
      return;
    }
    const arr = ethnicityColumns[0];
    const res = arr.filter(str => str.includes(searchText));
    setEthnicityArr([res]);
  };

  const handleNavigateSearch = () => {
    navigate('/admission');
  };

  // 正则表达式校验规则
  const idCardRule = {
    pattern: /^(^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[0-1])\d{3}(\d|X|x)$)$/,
    message: '请输入有效的身份证号码',
  };

  const emailRule = {
    type: 'email',
    message: '请输入有效的邮箱地址',
  };

  const phoneRule = {
    pattern: /^1[3-9]\d{9}$/,
    message: '请输入有效的手机号码',
  };

  return <div className="wrap">
    <ConfirmModal
      visible={isModalVisible}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    />
    <div className="page-header">
      <img className="img" src={LOGO} alt="logo" />
    </div>
    <div className="page-banner">
      <img className="img" src={BANNER} alt="logo" />
    </div>

    <div className="apply-title">{title1}</div>
    <div className="apply-desc">
      <div className="apply-desc-title">{title2}</div>
      <div>{p1}</div>
      <div style={{ marginTop: 8 }}>{p2}</div>
    </div>
    {reqSuccess && <div className="apply-result-card">
      <div style={{ color: '#B60408', fontSize: 20, fontWeight: 600, marginBottom: 20 }}>您的入学申请已提交</div>
      <div style={{ fontSize: 16 }}>请返回公众号「<span style={{ color: '#B60408' }} onClick={handleNavigateSearch}>录取查询</span>」菜单<br />查询入学录取结果</div>
    </div>}
    {!reqSuccess && <div className="form-body">
      <Form form={form}
            footer={
              <Button loading={isLoading} block type="submit" className="primaryColor" size="large" onClick={onSubmit}>
                提交
              </Button>
            }>
        <Form.Header>个人信息</Form.Header>
        <Form.Item name="student_name" label="姓名"
                   rules={[{ required: true, message: '请填写姓名' },
                     limit50]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="gender" label="性别"
                   rules={[{ required: true, message: '请选择性别' }]}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="男">男</Radio>
              <Radio value="女">女</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="ethnicity" label="民族"
                   trigger="onConfirm"
                   onClick={() => setPickerVisible(true)}
                   rules={[{ required: true, message: '请选择' }]}>
          <Picker
            visible={pickerVisible}
            // confirmText="确定"
            // cancelText=""
            title={<SearchBar placeholder="搜索" onChange={handlePcikViewSearch} clearable />}
            onClose={() => { setPickerVisible(false); }}
            columns={ethnicityArr}>
            {value => value?.[0]?.label ? value?.[0]?.label : '请选择民族'}
          </Picker>
        </Form.Item>
        <Form.Item name="date_of_birth" label="出生日期"
                   trigger="onConfirm"
                   onClick={() => setDatePickerVisible(true)}
                   rules={[{ required: true, message: '请选择出生日期' }]}>
          <DatePicker
            visible={datePickerVisible}
            min={dayjs().subtract(40, 'year').toDate()}
            max={dayjs().add(5, 'year').toDate()}
            onClose={() => { setDatePickerVisible(false); }}>
            {value => value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'}
          </DatePicker>
        </Form.Item>
        <Form.Item name="place_of_origin" label="籍贯"
                   rules={[{ required: true, message: '请填写籍贯' },
                     limit100]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="residence" label="户口所在地"
                   rules={[{ required: true, message: '请填写户口所在地' },
                     limit100]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="id_type" label="证件类型"
                   rules={[{ required: true, message: '请选择证件类型' }]}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="身份证">身份证</Radio>
              <Radio value="其他非大陆身份证或护照">其他非大陆身份证或护照</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="id_number" label="证件号"
                   rules={[{ required: true, message: '请填写证件号' },
                     limit100, idCardRule]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="phone" label="手机号"
                   rules={[{ required: true, message: '请填写手机号' },
                     limit20, phoneRule]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="email" label="邮箱"
                   rules={[{ required: true, message: '请填写邮箱' },
                     limit100, emailRule]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="mailing_address" label="邮寄通知书地址"
                   rules={[{ required: true, message: '请填写邮寄通知书地址' },
                     limit100]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="mailing_contact" label="邮寄联系人"
                   rules={[{ required: true, message: '请填写邮寄联系人' },
                     limit20]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="mailing_contact_phone" label="邮寄联系电话"
                   rules={[{ required: true, message: '请填写邮寄联系电话' , phoneRule},
                     limit20]}>
          <Input placeholder="请输入" />
        </Form.Item>

        <Form.Header>学习经历</Form.Header>
        <Form.Item name="education" label="学历"
                   rules={[{ required: true, message: '请填写学历' },
                     limit20]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="school" label="就读学校" rules={[{ limit100 }]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="graduation_date" label="毕业时间"
                   trigger="onConfirm"
                   onClick={() => setGraduationDatePickerVisible(true)}>
          <DatePicker
            min={dayjs().subtract(30, 'year').toDate()}
            max={dayjs().add(5, 'year').toDate()}
            visible={graduationDatePickerVisible}
            onClose={() => { setGraduationDatePickerVisible(false); }}>
            {value => value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'}
          </DatePicker>
        </Form.Item>
        <Form.Item name="foreign_language_score" label="外语成绩" rules={[{ limit20 }]}>
          <Input placeholder="请输入" />
        </Form.Item>

        <Form.Header>专业选择</Form.Header>
        <Form.Item name="major_category" label="报考专业类别"
                   rules={[{ required: true, message: '请选择报考专业类别' }, limit100]}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="艺术类">艺术类</Radio>
              <Radio value="信息类">信息类</Radio>
              <Radio value="商科类">商科类</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>

        <Form.Header>家庭情况</Form.Header>
        <Form.Item name="family_contacts" label="家长或紧急联系人"
                   rules={[{ required: true, message: '请至少添加一位联系人' }]}>
          <ContactComp outerForm={form} />
        </Form.Item>

        <Form.Item name="international_project_center_contact" label="国际项目中心联系老师" rules={[{ limit50 }]}>
          <Input placeholder="请输入" />
        </Form.Item>

      </Form>
    </div>}
  </div>;
};
export default ApplyView;
