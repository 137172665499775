import dayjs from 'dayjs'
import { PAGE_HIERARCHY } from '../routesConfig';


export const getWeekdayInChinese = (date) => {
  const weekdays = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
  const dayIndex = dayjs(date).day();
  return weekdays[dayIndex];
}

export const  formatTime = (time) => {
  // 检查是否为完整时间格式
  if (String(time).includes(':')) {
    return time; // 已经是 "20:00" 这样的格式，直接返回
  } else {
    return `${time}:00`; // 纯数字，添加 ":00" 以得到 "20:00" 的格式
  }
}

function isPathMatch(pattern, path) {
  const patternParts = pattern.split('/');
  const pathParts = path.split('/');
  if (patternParts.length !== pathParts.length) {
    return false;
  }
  for (let i = 0; i < patternParts.length; i++) {
    const patternPart = patternParts[i];
    const pathPart = pathParts[i];

    if (patternPart !== pathPart && !patternPart.startsWith(':')) {
      return false;
    }
  }
  return true;
}

export const getPageConfig = (pathname) => {
  const currentPageConfigKey = Object.keys(PAGE_HIERARCHY).find(key => {
    return isPathMatch(key, pathname);
  });
  return PAGE_HIERARCHY[currentPageConfigKey];
}

export const activityStatus = (activity) => {
  if (!activity) {
    return null;
  }
  const now = dayjs();
  const activityStartTime = dayjs(`${activity.event_date} ${activity.start_time}:00`);
  const activityEndTime = dayjs(`${activity.event_date} ${activity.end_time}:00`);

  // 检查当前时间是否在活动时间范围内
  if (now.isAfter(activityStartTime) && now.isBefore(activityEndTime)) {
    return '活动中'
  }

  // 检查当前时间是否在活动开始时间之前
  if (now.isBefore(activityStartTime)) {
    return '报名中'
  }

  // 检查活动是否已经结束且状态为0
  if (now.isAfter(activityEndTime) && activity.status === 0) {
    return '待结算'
  }

  // 检查活动是否已经结束且状态为1
  if (now.isAfter(activityEndTime) && activity.status === 1) {
    return '已结算'
  }
  return null;
}


export function formatNumberToCurrency(num) {
  if (num === undefined || num === null) {
    return 0;
  }
  return num.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
