export const PAGE_HIERARCHY = {
  '/': {
    key: '',
    level: 1,
    children: []
  },
  '/apply': {
    key: 'apply', // key是指所属tab
    level: 1,        // level是指层级1
    children: ['/apply/:id', '/apply/search']
  },
  '/reserve': {
    key: 'reserve', // key是指所属tab
    level: 1,        // level是指层级1
    children: ['/reserve/:id']
  }
  // ... 其他页面
};
