import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Apply from './Pages/Apply/index';
import Appointment from './Pages/Appointment/index';
import { getPageConfig } from './utils';
import './MainLayout.scss'
import Admission from './Pages/Admission'


function MainLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const pageConfig = getPageConfig(location.pathname);
  console.log(pageConfig);
  console.log(location.pathname)
  return (<div >
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/apply" replace />} />
        <Route path="/apply" element={<Apply />} />
        <Route path="/appointment" element={<Appointment />} />
        <Route path="/admission" element={<Admission />} />
      </Routes>
    </div>
  </div>);
}

export default MainLayout;
