import React, { useState } from 'react';
import './admission.scss';
import { Fetch } from '../../utils/fetchData';
import {
  Form,
  Input,
  Button,
} from 'antd-mobile';
import BANNER from '../../assets/banner.png';
import LOGO from '../../assets/logo_white.png';
import {
  limit20,
  limit50,
  limit100,
  admissionStatusMap,
  defaultAdmissionStatus,
} from '../../utils/check';

const Admission = () => {

  const title1 = '华侨大学日本国际教育项目';
  const title2 = '录取查询';
  const p1 = '华侨大学日本国际教育项目的自主招生考试时间持续更新,请考生关注微信公众号及学院招生办老师发布通知信息。';
  const p2 = '咨询电话:0595-22692190';

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [reqSuccess, setReqSuccess] = useState(false);
  const [admissionStatus, setAdmissionStatus] = useState('');

  const statusInfo = admissionStatusMap[admissionStatus] || defaultAdmissionStatus;

  const onSearch = () => {
    form.validateFields()
      .then((res) => {
        setIsLoading(true);
        Fetch.GET('/api/applications/id_number', {
          id_number: res.id_number,
          student_name: res.student_name,
          phone: res.phone
        })
          .then(res => {
            setIsLoading(false);
            setReqSuccess(true);
            setAdmissionStatus(res?.admission_status);
          })
          .catch(() => {
            setIsLoading(false);
          });
      })
      .catch((err) => {
        console.log('====== err', err);
      });
  };

  return <div className="wrap">
    <div className="page-header">
      <img className="img" src={LOGO} alt="logo" />
    </div>
    <div className="page-banner">
      <img className="img" src={BANNER} alt="logo" />
    </div>

    <div className="apply-title">{title1}</div>
    <div className="apply-desc">
      <div className="apply-desc-title">{title2}</div>
      <div>{p1}</div>
      <div style={{ marginTop: 8 }}>{p2}</div>
    </div>
    {reqSuccess && (<div className="admission-result-wrap">
      <div className="admission-result-card" style={{ backgroundColor: statusInfo?.bgColor }}>
        <div className="title" style={{ color: statusInfo?.color }}>{statusInfo?.title}</div>
        <div className="text" style={{ color: statusInfo?.color, whiteSpace: 'pre-wrap' }}>{statusInfo?.text}</div>
      </div>
      <Button loading={isLoading}
              block
              className="primaryColor"
              size="large"
              onClick={() => {
                setReqSuccess(false);
                setAdmissionStatus('');
              }}>
        确定
      </Button>
    </div>)}
    {!reqSuccess && <div className="form-body">
      <Form form={form}
            footer={
              <Button loading={isLoading} block type="submit" className="primaryColor" size="large" onClick={onSearch}>
                查询
              </Button>
            }>
        <Form.Item name="student_name" label="姓名"
                   rules={[{ required: true, message: '请填写姓名' },
                     limit50]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="phone" label="手机号"
                   rules={[{ required: true, message: '请填写手机号' },
                     limit20]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="id_number" label="证件号"
                   rules={[{ required: true, message: '请填写证件号' },
                     limit100]}>
          <Input placeholder="请输入" />
        </Form.Item>
      </Form>
    </div>}
  </div>;
};

export default Admission;
