import React, { useState, useEffect } from 'react'
import './contactComp.scss'
import { cloneDeep } from 'lodash'

import {
  Form,
  Input,
  Radio,
  Space,
  Button,
  Popup,
} from 'antd-mobile'
import {
  limit20,
  limit50,
  limit100,
} from '../../utils/check'

const ContactComp = ({ onChange, value }) => {

  const [form] = Form.useForm()

  const defaultData = value?.length ? value : []
  const [data, setData] = useState(defaultData)
  const [visible, setVisible] = useState(false)
  const [showOtherRelation, setShowOtherRelation] = useState(false)
  const [otherRelation, setOtherRelation] = useState('')

  useEffect(() => {

  }, )

  const onSubmit = () => {
    form.validateFields()
      .then((res) => {
        const familyContacts = value?.length ? cloneDeep(value) : []
        if (res.relationship === '其他' && otherRelation?.length > 0) {
          res.relationship = `${otherRelation}`
        }
        familyContacts.push(res)
        onChange(familyContacts)
        setData(familyContacts)
        setVisible(false)
        console.log('====== res', res)
      })
      .catch((err) => {
        console.log('====== err', err)
      })
  }

  return <div className="contact_wrap">
    {data?.map((el, idx) => (<div className="card" key={idx}>
      <div className="card-left">
        <p >{`${el?.name}`}</p>
        {el.relationship ? <p>{`关系：${el.relationship}`}</p> : <></>}
        <p>{`电话：${el?.phone}`}</p>
        <p>{`单位：${el?.company}`}</p>
        <p>{`职务：${el?.position}`}</p>
      </div>
      <div className="card-right">
        <span onClick={()=>{
          const familyContacts = value?.length ? cloneDeep(value) : []
          familyContacts.splice(idx, 1)
          onChange(familyContacts)
          setData(familyContacts)
        }}>删除</span>
      </div>
    </div>))}
    {data?.length < 5 && <>
      <Button className="primaryColor"
              onClick={() => {
                form.resetFields()
                setVisible(true)
                setOtherRelation('')
                setShowOtherRelation(false)
              }}>
        添加
      </Button>
      <p className="remark">
        最多添加5行记录
      </p>
    </>}
    <Popup
      position='right'
      visible={visible}
      bodyClassName="contact_popup">
      <div className="contact_modal">
        <h2 className="title">添加联系人</h2>
        <div className="content">
          <Form form={form}
                onValuesChange={(changedValues) => {
                  if (changedValues?.relationship) {
                    setShowOtherRelation(changedValues.relationship === '其他')
                  }
                }}>
            <Form.Item name="name" label="姓名"
                       rules={[{ required: true, message: '请填写姓名' }, limit50]}>
              <Input placeholder='请输入' />
            </Form.Item>
            <Form.Item name='relationship' label='与学生关系'
                       rules={[{ required: true, message: '请选择与学生的关系' }]}>
              <Radio.Group>
                  <Space direction="vertical">
                    <Radio value="父亲">父亲</Radio>
                    <Radio value="母亲">母亲</Radio>
                    <Radio value="其他">其他</Radio>
                  </Space>
                </Radio.Group>
            </Form.Item>
            {showOtherRelation && <Input value={otherRelation}
                                         maxLength={20}
                                         className="supplement_input"
                                         placeholder="请输入补充内容（选填）"
                                         onChange={val => setOtherRelation(val)}/>}
            <Form.Item name="phone" label="手机号"
                       rules={[{ required: true, message: '请填写手机号' }, limit20]}>
              <Input placeholder='请输入' />
            </Form.Item>
            <Form.Item name="company" label="单位"
                       rules={[{ required: true, message: '请填写单位' }, limit100]}>
              <Input placeholder='请输入' />
            </Form.Item>
            <Form.Item name="position" label="职务"
                       rules={[{ required: true, message: '请填写职务' }, limit50]}>
              <Input placeholder='请输入' />
            </Form.Item>
          </Form>
        </div>
        <div className="footer">
          <Button size="large" onClick={() => {
            setVisible(false)
          }}>取消</Button>
          <Button className="primaryColor" size="large" onClick={onSubmit}>确定</Button>
        </div>

      </div>
    </Popup>
  </div>
}
export default ContactComp
