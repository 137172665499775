import React, { useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';

const ConfirmModal = ({ visible, onConfirm, onCancel }) => {
  const [checked, setChecked] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
  };

  const handleOk = () => {
    onConfirm(); // 执行确认回调
  };

  return (
    <Modal
      title="提示"
      visible={visible}
      onOk={handleOk}
      okButtonProps={{ disabled: !checked, loading: confirmLoading, style: { backgroundColor: '#B60408', borderColor: '#B60408' } }}
      onCancel={onCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk} disabled={!checked} loading={confirmLoading}>
          同意确认
        </Button>,
      ]}
      closable={false}
      maskClosable={false}
      style={{ marginTop: '-5vh' }}
    >
      <style>
        {`
          .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #B60408;
            border-color: #B60408;
          }
          .ant-checkbox-checked::after {
            border-color: #B60408;
          }
          .ant-checkbox-checked .ant-checkbox-inner::after {
            border-color: #fff;
          }
          .ant-checkbox:hover .ant-checkbox-inner,
          .ant-checkbox-input:focus + .ant-checkbox-inner {
            border-color: #B60408;
          }
          .ant-btn-primary {
            background-color: #B60408;
            border-color: #B60408;
          }
          .ant-btn-primary:hover,
          .ant-btn-primary:focus {
            background-color: #930307;
            border-color: #930307;
          }
        `}
      </style>
      <div style={{ whiteSpace: 'pre-wrap', maxHeight: '60vh', overflowY: 'auto' }}>
        {`       根据《中华人民共和国网络安全法》规定，就华侨大学继续教育学院招生之网络报名系统，涉及报名学生个人信息及报名其他相关事项，特做出如下明示告知。本须知在填写报名申请表之前显示，请拟报名学生在填写报名表之前详细阅读理解，无异议后签名并与入学申请表及相关报名资料同时上传提交网络报名系统。拟报名学生若未年满18周岁，请其监护人一并阅读、签字提交。

1、报名学生/及其监护人向招生网络报名系统提交的申请表，其中所填写和上传的相关信息将作为华侨大学继续教育学院招生录取审核使用。

2、报名学生/及其监护人授权华侨大学继续教育学院使用其填写的个人信息资料，用以查询报名学生有关报名申请事宜及其学历情况。

3、报名学生被录取后，华侨大学继续教育学院将使用其填报的个人信息资料，建立学生学籍档案，办理学生证、出入宿舍门禁系统以及作为教学的其他日常管理之用。

4、报名学生/及其监护人承诺所填报的相关资料均为真实有效。若有虚假填报，报名学生及其监护人将自行承担因此所产生的一切后果及责任。

5、报名学生填报的邮箱、地址、微信、电话等联系方式，必须得有效使用且报名学生可从该联系方式收悉邮件、信件、语音或文字信息资料，华侨大学继续教育学院根据填报的联系方式发出的邮件、信件、语音或文字信息资料，视为已经送达报名学生/及其监护人。

6、华侨大学继续教育学院招生报名系统对学生填写的个人信息将严格保密，系统存储数据时将对学生的身份证号信息采用加密保存，即便出现信息被盗用或其他泄露情形，盗用者也将无法正常识别和使用该信息。

7、报名学生填报的相关信息，华侨大学继续教育学院仅作为招生录取、教学及学校日常管理之用，不做其他用途；不向无关事宜对象披露，不得出售或者非法向他人提供。

8、报名学生被录取后，须自觉遵守校纪校规，养成良好的行为习惯，共同营造优良学风。

9、若发生不可抗力及意外事件风险，包括但不限于自然灾害、战争或国家政策变化等不能预见、不能避免、不能克服的不可抗力事件导致的任何信息损失或无法正常使用，华侨大学继续教育学院依法不承担相关责任。

本须知在填写学生入学申请表前已显示，本报名学生/及监护人对本须知提示内容已完整阅读并完全理解，对本须知中有关填报的个人信息使用及其他相关告知事项完全清楚并同意！`}
      </div>
      <Checkbox onChange={handleCheckboxChange} style={{ marginTop: 16 }}>
        我已阅读，并同意以上须知。
      </Checkbox>
    </Modal>
  );
};

export default ConfirmModal;
