

// 待审批状态：0、通过后为1、失败为：2
export const admissionStatusMap = {
  0: { color: '#ff8b00', bgColor: '#f5eadc', title:'请耐心等待',text:'您的入学申请「正在审批中」' },
  1: { color: '#B60408', bgColor: '#f6e5e5', title:'恭喜您',text:`祝贺你被华侨大学继续教育学院录取，成为华侨大学日本国际教育项目本科学生，正式录取通知书及《新生入学报到须知》待我校开学报到时间确定后通过中国邮政快递陆续寄出。\n\n如有疑问可通过华侨大学继续教育学院官方微信公众号（华侨大学继续教育学院-【关于学院】-【录取查询】）进行查询或致电 0595-22692190` },
  2: { color: '#333333', bgColor: '#dddddd', title:'很遗憾',text:'您的入学申请「未通过」' },
}

export const defaultAdmissionStatus = { color: '#333333', bgColor: '#dddddd', title: '抱歉', text: '您的入学申请「未找到」' }

export const limit20 = { max: 20, message: '不可超过20个字符' }
export const limit50 = { max: 50, message: '不可超过50个字符' }
export const limit100 = { max: 100, message: '不可超过100个字符' }

export const ethnicityColumns = [[
  // { label: '汉族', value: '汉族' },
  '汉族',
  '蒙古族',
  '藏族',
  '苗族',
  '壮族',
  '回族',
  '维吾尔族',
  '彝族',
  '布依族',
  '朝鲜族',
  '侗族',
  '白族',
  '哈尼族',
  '傣族',
  '傈僳族',
  '畲族',
  '拉祜族',
  '满族',
  '瑶族',
  '土家族',
  '哈萨克族',
  '黎族',
  '佤族',
  '高山族',
  '水族',
  '东乡族',
  '景颇族',
  '土族',
  '仫佬族',
  '布朗族',
  '毛南族',
  '锡伯族',
  '普米族',
  '纳西族',
  '柯尔克孜族',
  '达斡尔族',
  '羌族',
  '撒拉族',
  '仡佬族',
  '阿昌族',
  '塔吉克族',
  '怒族',
  '俄罗斯族',
  '德昂族',
  '裕固族',
  '塔塔尔族',
  '鄂伦春族',
  '门巴族',
  '基诺族',
  '乌孜别克族',
  '鄂温克族',
  '保安族',
  '京族',
  '独龙族',
  '赫哲族',
  '珞巴族',
]]
