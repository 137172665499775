import React, { useState } from 'react';
import { Fetch } from '../../utils/fetchData';
import './appointment.scss';
import {
  Form,
  Input,
  Radio,
  Space,
  Button, DatePicker, Toast
} from 'antd-mobile';
import BANNER from '../../assets/banner.png';
import LOGO from '../../assets/logo_white.png';
import {
  limit20,
  limit50,
  limit100,
} from '../../utils/check';
import dayjs from 'dayjs';

const Appointment = () => {

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false)
  const [reqSuccess, setReqSuccess] = useState(false)
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  const title1 = '入校人员信息报备';
  const p1 = '欢迎预约来访华侨大学！温馨提示：根据校区要求，凡是入校必须填写以下信息：';
  const p2 = '请申请入校的家长同学们，于入校前一天15：00前完成信息填报，以便上报系统审核，不影响进校进程。';

  const formatTimeValue = (time) => {
    return dayjs(time).unix();
  };

  const onSubmit = () => {
    form.validateFields()
      .then((res) => {
        setIsLoading(true)
        if (res?.appointment_date) {
          res.appointment_date = formatTimeValue(res.appointment_date);
        }
        Fetch.POST('/api/appointments', {}, res).then(res => {
          if(res.code === 200) {
            setIsLoading(false)
            setReqSuccess(true)
          }else{
            Toast.show({ content: '请求失败请联系管理员', duration: 3000 })
          }
        }).catch(() => {
          setIsLoading(false)
        });
      })
      .catch((err) => {
        console.log('====== err', err);
      });
  };

  const handleOk = () => {
    window.location.reload();
  }
  // 正则表达式校验规则
  const idCardRule = {
    pattern: /^(^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[0-1])\d{3}(\d|X|x)$)$/,
    message: '请输入有效的身份证号码',
  };

  const phoneRule = {
    pattern: /^1[3-9]\d{9}$/,
    message: '请输入有效的手机号码',
  };

  return <div className="wrap">
    <div className="page-header">
      <img className="img" src={LOGO} alt="logo" />
    </div>
    <div className="page-banner">
      <img className="img" src={BANNER} alt="logo" />
    </div>

    <div className="apply-title">{title1}</div>
    <div className="apply-desc">
      <div style={{ marginTop: 12 }}>{p1}</div>
      <div style={{ marginTop: 8 }}>{p2}</div>
    </div>
    {reqSuccess && <><div className="apply-result-card">
      <div style={{ color: '#B60408', fontSize: 20, fontWeight: 600, marginBottom: 20 }}>您的入校申请已提交</div>
      <div style={{ color: '#B60408', fontSize: 16, marginBottom: 30 }}>请按预约时间入校，华侨大学欢迎您的到访！</div>
    </div>
      <div className='apply-result-btn'>
        <Button block type="submit" className="primaryColor"  onClick={handleOk}>
          确定
        </Button>
      </div>
    </>
    }
    {!reqSuccess && <div className="form-body">
      <Form form={form}
            footer={
              <Button loading={isLoading} block type="submit" className="primaryColor" size="large" onClick={onSubmit}>
                提交
              </Button>
            }>
        <Form.Header>个人信息</Form.Header>
        <Form.Item name="name" label="姓名"
                   rules={[{ required: true, message: '请填写姓名' },
                     limit50]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="gender" label="性别"
                   rules={[{ required: true, message: '请选择性别' }]}>
          <Radio.Group>
            <Space direction="vertical">
              <Radio value="男">男</Radio>
              <Radio value="女">女</Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="phone" label="手机号"
                   rules={[{ required: true, message: '请填写手机号' },
                     limit20, phoneRule]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="id_number" label="身份证号"
                   rules={[{ required: true, message: '请填写证件号' },
                     limit100, idCardRule]}>
          <Input placeholder="请输入" />
        </Form.Item>
        <Form.Item name="current_residence" label="现居住地"
                   rules={[{ required: true, message: '请填写邮寄通知书地址' },
                     limit100]}>
          <Input placeholder="请输入" />
        </Form.Item>

        <Form.Item name="appointment_date" label="预约日期"
                   trigger="onConfirm"
                   onClick={() => setDatePickerVisible(true)}
                   rules={[{ required: true, message: '请选择出生日期' }]}>
          <DatePicker
            visible={datePickerVisible}
            onClose={() => {setDatePickerVisible(false);}}>
            {value => value ? dayjs(value).format('YYYY-MM-DD') : '请选择日期'}
          </DatePicker>
        </Form.Item>
      </Form>
    </div>}
  </div>;
};
export default Appointment;
